<template>
    <div class="view employees">
        <section class="hero is-dark is-bold">
            <div class="bg-filter"></div>
            <div class="hero-body">
                <div class="container is-flex">
                    <h1 class="title">
                        We have 5 employees.
                    </h1>
                </div>
            </div>
            <div class="hero-foot">
                <nav class="tabs is-boxed is-fullwidth">
                    <div class="container">
                        <ul>
                            <li v-bind:class="{ 'is-active': $route.path.includes('/employee') }">
                                <router-link to="/employee">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="user"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Employees
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "Employee",
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.hero {
    background-image: url(~@/assets/hero_background_2.png) !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    & > .hero-body {
        & > .container {
            width: 100%;
        }
    }

    & > .hero-foot {
        .tabs {
            ul {
                margin-left: 10px;
                margin-right: 10px;

                li {
                    &.is-active {
                        color: #363636 !important;
                    }
                }
            }
        }
    }
}
</style>
